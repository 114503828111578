export const CHUNK_SIZE = 50;

const PLOT_SIZE = 40;
const GAP_SIZE = 10;
const SHIFT_FACTOR = 5;

export const HORIZ_BOUNDARY = 14;
export const UPPER_BOUNDARY = 3;

export function isOutOfBounds(chunkX: number, chunkY: number, chunkZ: number) {
  return (
    chunkX > HORIZ_BOUNDARY ||
    chunkZ > HORIZ_BOUNDARY ||
    chunkX < -HORIZ_BOUNDARY ||
    chunkZ < -HORIZ_BOUNDARY ||
    chunkY > UPPER_BOUNDARY
  );
}

export function inBuildZone(x: number, y: number, z: number) {
  if (x < 0) {
    x -= GAP_SIZE;
  }

  if (z < 0) {
    z -= GAP_SIZE;
  }

  x += SHIFT_FACTOR;
  z += SHIFT_FACTOR;

  return (
    Math.abs(x) % (PLOT_SIZE + GAP_SIZE) > GAP_SIZE &&
    Math.abs(z) % (PLOT_SIZE + GAP_SIZE) > GAP_SIZE
  );
}

// TODO optimize for powers of 2?
export function worldCoordToChunkCoord(coord: number) {
  return Math.floor(coord / CHUNK_SIZE) | 0;
}
export function worldCoordToChunkIndex(coord: number) {
  return ((coord % CHUNK_SIZE) + CHUNK_SIZE) % CHUNK_SIZE | 0;
}
