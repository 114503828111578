var vec3 = require('gl-vec3');

export default function (noa) {
  return {
    name: 'mesh',
    order: 100,
    state: {
      mesh: null,
      offset: null
    },
    onAdd: function onAdd(eid, state) {
      // implicitly assume there's already a position component
      var posDat = noa.ents.getPositionData(eid);

      if (state.mesh) {
        noa.rendering.addMeshToScene(state.mesh, false, posDat.position);
      } else {
        throw new Error('Mesh component added without a mesh - probably a bug!');
      }

      if (!state.offset) state.offset = new vec3.create(); // set mesh to correct position

      var rpos = posDat._renderPosition; // TODO use quaternions

      state.mesh.rotationQuaternion = null;
      state.mesh.position.copyFromFloats(rpos[0] + state.offset[0], rpos[1] + state.offset[1], rpos[2] + state.offset[2]);
    },
    onRemove: function onRemove(eid, state) {
      state.mesh.dispose();
    },
    renderSystem: function renderSystem(dt, states) {
      // before render move each mesh to its render position, 
      // set by the physics engine or driving logic
      var ents = noa.entities;
      states.forEach(function (state) {
        var id = state.__id;
        var moveState = ents.getMovement(id); // TODO

        if (moveState && moveState.heading) {
          state.mesh.rotation.y = moveState.heading + Math.PI;
        }

        var rpos = noa.ents.getPositionData(id)._renderPosition;

        state.mesh.position.copyFromFloats(rpos[0] + state.offset[0], rpos[1] + state.offset[1], rpos[2] + state.offset[2]);
      });
    }
  };
}