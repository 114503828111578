import { getClient } from "./nengiClient";
import { noa } from "../voxel/noaInit";
import MoveCommand from "../common/commands/MoveCommand";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { StandardMaterial } from "@babylonjs/core/Materials";
import { Color3 } from "@babylonjs/core/Maths/math";

let client = getClient();

let lastPos = [0, 0, 0];
let players = new Map();
let myEntityId = 0;

/**
 * Called every noa tick, reads network state from nengi
 */
export function networkUpdate() {
  // TODO can we cache this between updates?

  var player = noa.playerEntity;
  let dat = noa.entities.getPositionData(player);
  let scene = noa.rendering.getScene();

  var w = dat.width;
  var h = dat.height;

  const network = client.readNetwork();

  network.messages.forEach((message: any) => {
    // Figure out your own nengi ID
    // So you don't process updates from yourself
    if (message.protocol.name === "IdentityCommand") {
      myEntityId = message.entityId;
    }
  });

  client.update();

  // Update position + orientation to server, if it changed
  if (
    dat.position[0] != lastPos[0] ||
    dat.position[1] != lastPos[1] ||
    dat.position[2] != lastPos[2]
  ) {
    // Send update
    let command = new MoveCommand(
      dat.position[0],
      dat.position[1],
      dat.position[2]
    );
    client.addCommand(command);
  }

  lastPos[0] = dat.position[0];
  lastPos[1] = dat.position[1];
  lastPos[2] = dat.position[2];

  network.entities.forEach((snapshot: any) => {
    snapshot.createEntities.forEach((entity: any) => {
      if (entity.protocol.name === "PlayerEntity") {
        if (entity.nid === myEntityId) {
          return;
        }

        var newMesh = Mesh.CreateBox("playerMesh" + entity.nid, 1, scene);
        let newMat = new StandardMaterial(Math.random() + "", scene);
        newMat.diffuseColor = new Color3(
          Math.random(),
          Math.random(),
          Math.random()
        );
        newMesh.material = newMat;

        newMesh.scaling.x = w;
        newMesh.scaling.z = w;
        newMesh.scaling.y = h / 1.5;

        let head = Mesh.CreateBox("playerMesh_head" + entity.nid, 1, scene);
        let newMat2 = new StandardMaterial(Math.random() + "", scene);
        newMat2.diffuseColor = new Color3(
          Math.random(),
          Math.random(),
          Math.random()
        );
        head.material = newMat2;

        head.position.y += 0.8;
        head.parent = newMesh;
        head.scaling.y /= 2;

        let newPos = [entity.x, entity.y, entity.z];
        let test = noa.entities.add(newPos, w, h, newMesh, [0, h / 1.5 / 2, 0]);
        noa.rendering.addMeshToScene(head, false, newPos);

        players.set(entity.nid, test);
        console.log(`Players online: ${players.size}`);
      }
    });

    snapshot.updateEntities.forEach((update: any) => {
      let nid = update.nid;
      if (nid === myEntityId) {
        return;
      }

      let noaId = players.get(nid);

      if (!noaId) {
        return;
      }

      if (update.prop === "x" || update.prop === "y" || update.prop === "z") {
        let x = noa.entities.getPositionData(noaId).position[0];
        let y = noa.entities.getPositionData(noaId).position[1];
        let z = noa.entities.getPositionData(noaId).position[2];

        if (update.prop === "x") {
          x = update.value;
        }

        if (update.prop === "y") {
          y = update.value;
        }

        if (update.prop === "z") {
          z = update.value;
        }

        noa.entities.setPosition(noaId, x, y, z);
      }
    });

    snapshot.deleteEntities.forEach((nid: number) => {
      let noaId = players.get(nid);

      if (noaId) {
        noa.entities.deleteEntity(noaId);
      }
    });
  });
}
