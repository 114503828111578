import { BLOCK_ID } from "../../common/constants/BLOCKS";

type Item = BlockItem;

interface BlockItem {
  type: "block";
  blockId: BLOCK_ID;
}

interface InventoryState {
  slots: {
    slot1: Item;
    slot2: Item;
    slot3: Item;
    slot4: Item;
    slot5: Item;
    slot6: Item;
    slot7: Item;
    slot8: Item;
  };
  activeItemId: BLOCK_ID;
}

const initialState: InventoryState = {
  slots: {
    slot1: {
      type: "block",
      blockId: "grass"
    },
    slot2: {
      type: "block",
      blockId: "blue"
    },
    slot3: {
      type: "block",
      blockId: "red"
    },
    slot4: {
      type: "block",
      blockId: "road"
    },
    slot5: {
      type: "block",
      blockId: "wood"
    },
    slot6: {
      type: "block",
      blockId: "gold"
    },
    slot7: {
      type: "block",
      blockId: "orange"
    },
    slot8: {
      type: "block",
      blockId: "purple"
    }
  },
  activeItemId: "grass"
};

export type SlotId = keyof typeof initialState.slots;

export const inventoryReducer = (
  state = initialState,
  action: any
): InventoryState => {
  let result;
  switch (action.type) {
    case "SET_INVENTORY_SLOT":
      let slotId = action.slotId as SlotId;
      result = {
        ...state
      };

      result.slots[slotId].blockId = action.itemId;
      return result;
    case "SET_ACTIVE_ITEM":
      let itemId = action.itemId;
      result = {
        ...state
      };
      result.activeItemId = itemId;
      return result;
    default:
      return state;
  }
};
