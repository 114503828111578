var vec3 = require("gl-vec3");

export default function (noa) {
  return {
    name: "buttonInputs",
    order: 19,
    state: {
      buttonStates: {
        upPressed: false,
        downPressed: false,
        flyPressed: false
      }
    },
    onAdd: function onAdd(eid, state) {
      if (state.buttonStates) {
        this.buttonStates = state.buttonStates;
      }
    },
    onRemove: function onRemove(eid, state) {//
    },
    renderSystem: function renderSystem(dt, states) {}
  };
}