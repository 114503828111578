import * as React from "react";
import { noa } from "../../voxel/noaInit";
import ReactNipple from "react-nipple";

// optional: include the stylesheet somewhere in your app
import "react-nipple/lib/styles.css";

export interface IDPadProps {}

interface DPadButtons {
  joystickActive: boolean;
  angle: number;
}

type keyName = "left" | "right" | "forward" | "backward";

export default class DPad extends React.PureComponent<IDPadProps> {
  dPadButtons: DPadButtons;

  constructor(props: IDPadProps) {
    super(props);

    this.dPadButtons = {
      joystickActive: false,
      angle: 0
    };

    // Add a component to the thingy which passes in dPadButtons
    var player = noa.playerEntity;

    noa.entities.addComponent(player, noa.entities.names.virtualInputs, {
      dPadInputs: this.dPadButtons
    });
  }

  convertAngleToButtons = (angle: number) => {
    this.dPadButtons.angle = angle;
    this.dPadButtons.joystickActive = true;
  };

  onJoystickEnd = () => {
    this.dPadButtons.joystickActive = false;
  };

  public render() {
    return (
      <div className="DPad">
        <ReactNipple
          // supports all nipplejs options
          // see https://github.com/yoannmoinet/nipplejs#options
          options={{ mode: "static", position: { bottom: "50%", left: "50%" } }}
          // any unknown props will be passed to the container element, e.g. 'title', 'style' etc
          style={{
            width: 100,
            height: 100,
            marginBottom: "8px"
            // if you pass position: 'relative', you don't need to import the stylesheet
          }}
          // all events supported by nipplejs are available as callbacks
          // see https://github.com/yoannmoinet/nipplejs#start
          onMove={(evt: any, data: any) =>
            this.convertAngleToButtons(data.angle.degree)
          }
          onEnd={this.onJoystickEnd}
        />
      </div>
    );
  }
}

interface DPadButtonProps {
  text: string;
  onTouchStart(): void;
  onTouchEnd(): void;
}

function DPadButton(props: DPadButtonProps) {
  return (
    <div onTouchStart={props.onTouchStart} onTouchEnd={props.onTouchEnd}>
      {props.text}
    </div>
  );
}
