import { createStore } from "redux";
import reducer from "./reducers/root";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["inventory"]
};

const persistedReducer = persistReducer(persistConfig, reducer);

// Second argument here enables chrome extension
export const store = createStore(
  persistedReducer,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

export let persistor = persistStore(store);

let state = store.getState();
export type MyReduxState = typeof state;
