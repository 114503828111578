var vec3 = require('gl-vec3');

export default function (noa) {
  return {
    name: 'virtualInputs',
    order: 19,
    state: {
      dPadInputs: {
        left: false,
        right: false,
        forward: false,
        backward: false
      }
    },
    onAdd: function onAdd(eid, state) {
      if (state.dPadInputs) {
        this.dPadInputs = state.dPadInputs;
      }
    },
    onRemove: function onRemove(eid, state) {//
    },
    renderSystem: function renderSystem(dt, states) {}
  };
}