import { noa } from "./noaInit";
import "@babylonjs/loaders/glTF";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import {
  AnimationPropertiesOverride,
  AnimationGroup
} from "@babylonjs/core/Animations";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { StandardMaterial } from "@babylonjs/core/Materials";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
const queryString = require("query-string");

export let playerMesh: AbstractMesh | undefined;

class CharAnimator {
  currentGroup: AnimationGroup | undefined = undefined;
  map: Map<string, AnimationGroup> = new Map();

  addGroup(name: string, group: AnimationGroup) {
    this.map.set(name, group);
  }

  playGroup(name: string, isLooping: boolean) {
    if (this.currentGroup) {
      this.currentGroup.stop();
    }

    if (this.map.has(name)) {
      let group = this.map.get(name);
      if (!group) {
        return;
      }

      group.play(isLooping);
      this.currentGroup = group;
      return group;
    }
  }
}

export function initScene() {
  // get the player entity's ID and other info (position, size, ..)
  var player = noa.playerEntity;
  var dat = noa.entities.getPositionData(player);

  const parsed = queryString.parse(window.location.search);
  if (parsed && parsed.x && parsed.y && parsed.z) {
    let spawnLocations = [parsed.x, parsed.y, parsed.z];
    let spawnNumbers = spawnLocations.map(num => parseFloat(num));
    spawnNumbers = spawnNumbers.filter(num => !isNaN(num));

    if (spawnNumbers.length === 3) {
      noa.entities.setPosition(
        player,
        spawnNumbers[0],
        spawnNumbers[1],
        spawnNumbers[2]
      );
    }
  } else {
    let randX = Math.floor(Math.random() * 6) - 3;
    let randZ = Math.floor(Math.random() * 6) - 3;

    // Spawn in a random location around the center.
    noa.entities.setPosition(player, 50 * randX, 2, 50 * randZ);
  }

  var w = dat.width;
  var h = dat.height;
}
