import * as firebase from "firebase";
require("firebase/functions");
require("firebase/firestore");

// Initialize Cloud Firestore through Firebase
firebase.initializeApp({
  apiKey: "AIzaSyDUvSBE_DhZuSBiD7eRUTyGxAaEyeblWcE",
  authDomain: "citycraft-ada47.firebaseapp.com",
  projectId: "citycraft-ada47"
});

firebase
  .auth()
  .signInAnonymously()
  .catch(function(error) {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    // ...
  });

export let setBlockFn = firebase.functions().httpsCallable("setBlock");
export let fire = firebase.firestore();
