import { worldCoordToChunkCoord, CHUNK_SIZE } from "./voxelUtils";
import { noa } from "./noaInit";
import { fire } from "../db/firebaseInit";

// -1000 number is to force a change on initial tick
let currentChunkX = -1000;
let currentChunkZ = -1000;
let currentChunkY = -1000;
let lastRandomChunkIndex = 0;

let subscriptions: (() => void)[] = [];
let lastChunkChangeTimestamp = Date.now();
let chunkRefreshTimer: NodeJS.Timeout | undefined;

function chunkToBox(chunkX: number, chunkY: number, chunkZ: number) {
  return {
    base: [chunkX * CHUNK_SIZE, chunkY * CHUNK_SIZE, chunkZ * CHUNK_SIZE],
    max: [
      chunkX * CHUNK_SIZE,
      (chunkY + 1) * CHUNK_SIZE - 1,
      chunkZ * CHUNK_SIZE
    ]
  };
}

export function updateOnTick(
  playerX: number,
  playerY: number,
  playerZ: number
) {
  // Get chunkX and chunkZ
  let chunkX = worldCoordToChunkCoord(playerX);
  let chunkY = worldCoordToChunkCoord(playerY);
  let chunkZ = worldCoordToChunkCoord(playerZ);

  // Triggered on switching chunks
  if (
    chunkX !== currentChunkX ||
    chunkZ !== currentChunkZ ||
    chunkY !== currentChunkY
  ) {
    currentChunkX = chunkX;
    currentChunkZ = chunkZ;
    currentChunkY = chunkY;

    let now = Date.now();
    // If it's been X seconds since you've switched chunks
    // Refresh around you
    // if (now - lastChunkChangeTimestamp > 10000) {
    //   refreshAreaAroundMe();
    // }

    // Refresh area around you when you go somewhere and stand still
    // But clear timeout when you move
    if (chunkRefreshTimer) {
      clearTimeout(chunkRefreshTimer);
    }
    chunkRefreshTimer = setTimeout(refreshAreaAroundMe, 30000);

    lastChunkChangeTimestamp = now;

    // Update Firestore listener
    readBlocks(currentChunkX, currentChunkZ);
  }
}

interface BlockUpdate {
  dateCreated: number;
  type: number;
  x: number;
  y: number;
  z: number;
}

function refreshAreaAroundMe() {
  if (currentChunkZ === -1000 || currentChunkZ === -1000) {
    return;
  }

  let baseChunkX = currentChunkX - 1;
  let baseChunkZ = currentChunkZ - 1;
  let baseChunkY = currentChunkY;

  let endChunkX = currentChunkX + 1;
  let endChunkZ = currentChunkZ + 1;
  let endChunkY = Math.min(currentChunkY + 1, 4);

  let box = {
    base: [
      baseChunkX * CHUNK_SIZE,
      baseChunkY * CHUNK_SIZE,
      baseChunkZ * CHUNK_SIZE
    ],
    max: [
      endChunkX * CHUNK_SIZE + 1,
      endChunkY * CHUNK_SIZE - 1,
      endChunkZ * CHUNK_SIZE + 1
    ]
  };

  noa.world.invalidateVoxelsInAABB(box);
  chunkRefreshTimer = undefined;
}

export function readBlocks(chunkX: number, chunkZ: number) {
  // Get clusterID
  let clusterId = `${chunkX}|${chunkZ}`;
  subscriptions.forEach(sub => sub());

  // Listen for Firestore
  let listener = fire
    .collection("chunks")
    .doc(clusterId)
    .collection("blocks")
    .where("dateCreated", ">", Date.now())
    .onSnapshot(function(snapshot) {
      snapshot.docChanges().forEach(function(change) {
        if (change.type === "added") {
          let newBlock = change.doc.data() as BlockUpdate;
          let blockCoords = [newBlock.x, newBlock.y, newBlock.z];

          // Place blocks upon receiving update
          noa.setBlock(newBlock.type, blockCoords);
        }
      });
    });

  subscriptions.push(listener);
}
