import React from "react";
import "./voxel/noaInit";
import "./css/App.css";
import "./css/BottomUi.css";
import { Reticle } from "./components/gameUi/Reticle";
import BlockSelect from "./components/gameUi/BlockSelect";
import InputHandler from "./components/input/InputHandler";
import Inventory from "./components/gameUi/Inventory";
import DPad from "./components/gameUi/DPad";
import FlyControls from "./components/gameUi/FlyControls";
import HelpBanner from "./components/gameUi/HelpBanner";
import Joyride, { CallBackProps } from "react-joyride";
import ErrorText from "./components/gameUi/ErrorText";
import LocationShare from "./components/gameUi/LocationShare";
import { disableBodyScroll } from "body-scroll-lock";
import GetStarted from "./components/GetStarted";
import { noa } from "./voxel/noaInit";
var isTouchDevice = require("is-touch-device");

interface AppState {
  isTouchDevice: boolean;
  steps: any;
  showPromotion: boolean;
  isStarted: boolean;
}

export default class App extends React.PureComponent<{}, AppState> {
  constructor(props: any) {
    super(props);

    let isTouch = isTouchDevice();
    this.state = {
      isTouchDevice: isTouch,
      showPromotion: true,
      isStarted: false,
      steps: [
        {
          target: ".App-HelpBanner",
          content: "Welcome to CityCraft.io! Here are the controls...",
          disableBeacon: true
        },
        {
          target: ".ReactNipple",
          content: "Drag on this to move around",
          disableBeacon: true
        },
        {
          target: ".FlyControls",
          content: "Use these to ascend or descend",
          disableBeacon: true
        },
        {
          target: ".blockSelect",
          content: isTouch
            ? "Here is your palette. Tap to change colors."
            : "Here is your palette. Use the hotkeys to switch colors.",
          disableBeacon: true
        },
        {
          target: ".reticle",
          content: isTouch
            ? "Enjoy building!"
            : "Click to start. ESC to leave. Enjoy building!",
          disableBeacon: true
        }
      ]
    };
  }

  componentDidMount() {
    disableBodyScroll(document.querySelector("#root"));
  }

  onOnboardingCallback(props: CallBackProps) {
    if (window.localStorage) {
      localStorage.setItem("onboardingStart", "true");
    }
  }

  isOnboardingActive() {
    if (window.localStorage) {
      if (localStorage.getItem("onboardingStart") === "true") {
        return false;
      }
    }

    return true;
  }

  onClickStart = () => {
    if (noa) {
      noa.container.setPointerLock(true);
      this.setState({
        isStarted: true
      });

      let el = document.getElementById("cpmStarAd");
      if (el) {
        el.style.display = "none";
      }
    }
  };

  render() {
    return (
      <div className="App">
        {!this.state.isStarted && (
          <GetStarted onClickStart={this.onClickStart} />
        )}
        {/* <Joyride
          callback={this.onOnboardingCallback}
          steps={this.state.steps}
          run={this.isOnboardingActive()}
          continuous={true}
        /> */}
        <ErrorText />
        <HelpBanner isTouchDevice={this.state.isTouchDevice} />
        <Reticle />
        <LocationShare />
        <Inventory />
        <div className="bottomUiContainer">
          {this.state.isTouchDevice && <DPad />}
          <BlockSelect />
          {this.state.isTouchDevice && <FlyControls />}
          <div />
        </div>
        <InputHandler />
        <canvas id="noa-canvas"></canvas>
      </div>
    );
  }
}
