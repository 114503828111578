import { noa } from "./noaInit";
import { getNoaBlockIdx, BLOCK_ID } from "../common/constants/BLOCKS";
import { store } from "../redux/store";
import PlaceBlockCommand from "../common/commands/PlaceBlockCommand";
import { getClient } from "../network/nengiClient";
import axios from "axios";
import {
  worldCoordToChunkCoord,
  worldCoordToChunkIndex,
  inBuildZone,
  isOutOfBounds
} from "./voxelUtils";
import { setError } from "../redux/actions/gameUiActions";
import { setBlockFn } from "../db/firebaseInit";

interface LocalBlockUpdate {
  x: number;
  y: number;
  z: number;
  type: number;
  timestamp: number;
}

let lastBlockTimestamp = 0;
export let localBlockMap = new Map<string, Set<LocalBlockUpdate>>();

// Periodicially remove expired blocks from local cache
setInterval(() => {
  let now = Date.now();

  localBlockMap.forEach((set, chunkId) => {
    let toRemove: LocalBlockUpdate[] = [];
    set.forEach(blockUpdate => {
      if (now - blockUpdate.timestamp > 5000) {
        toRemove.push(blockUpdate);
      }
    });

    toRemove.forEach(aRemoval => {
      set.delete(aRemoval);
    });
  });
}, 7000);

function setAndCheckTimestamp() {
  if (Date.now() - lastBlockTimestamp > 100) {
    lastBlockTimestamp = Date.now();
    return true;
  }

  store.dispatch(setError("Please wait 0.5s between blocks"));
  return false;
}

async function setBlock(type: number, blockPosition: number[]) {
  let x = blockPosition[0];
  let y = blockPosition[1];
  let z = blockPosition[2];

  let chunkX = worldCoordToChunkCoord(x);
  let chunkY = worldCoordToChunkCoord(y);
  let chunkZ = worldCoordToChunkCoord(z);

  if (isOutOfBounds(chunkX, chunkY, chunkZ)) {
    console.log("Out of bounds!");
    store.dispatch(setError("Block out of bounds!"));
    return;
  }

  if (!inBuildZone(x, y, z)) {
    store.dispatch(setError("Can't place in/above streets!"));
    console.log("Cant place block in public land!");
    return;
  }

  let chunkId = `${chunkX}|${chunkY}|${chunkZ}`;

  // Send command to nengi
  let blockPos = blockPosition;

  // Cloud fn for long term storage
  let body = {
    x: blockPos[0],
    y: blockPos[1],
    z: blockPos[2],
    type
  };

  if (!localBlockMap.has(chunkId)) {
    localBlockMap.set(chunkId, new Set<LocalBlockUpdate>());
  }

  let localBlocks = localBlockMap.get(chunkId);
  if (localBlocks) {
    localBlocks.add({
      ...body,
      timestamp: Date.now()
    });
  }

  noa.setBlock(type, blockPosition);

  try {
    await setBlockFn(body).then((response: any) => {
      // console.log("Successful post!", response);
    });
  } catch (e) {
    console.log("Failed to post block", body, e);
  }
}

export function eraseBlock() {
  if (noa.targetedBlock) {
    if (noa.targetedBlock.position[1] < 0) {
      return;
    }

    if (!setAndCheckTimestamp()) {
      return;
    }

    let blockPos = noa.targetedBlock.position;
    setBlock(0, blockPos);
  }
}

export function startInputHandlers() {
  noa.inputs.down.on("fire", async function() {
    if (!setAndCheckTimestamp()) {
      return;
    }

    let currentBlockNoaIdx = getNoaBlockIdx(
      store.getState().inventory.activeItemId as BLOCK_ID
    );

    if (!noa.targetedBlock) {
      return;
    }

    setBlock(currentBlockNoaIdx, noa.targetedBlock.adjacent);
  });

  // clear targeted block on on left click
  noa.inputs.down.on("alt-fire", function() {
    eraseBlock();
  });
}
