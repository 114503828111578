interface GameUiState {
  isInterfaceOpen: boolean;
  isError: number;
  errorReason: string;
}

const initialState: GameUiState = {
  isInterfaceOpen: false,
  isError: 0,
  errorReason: ""
};

export const gameUiReducer = (
  state = initialState,
  action: any
): GameUiState => {
  let result;
  switch (action.type) {
    case "TOGGLE_INVENTORY":
      result = {
        ...state
      };

      result.isInterfaceOpen = !state.isInterfaceOpen;
      return result;
    case "SET_ERROR":
      result = {
        ...state
      };

      result.isError = Date.now();
      result.errorReason = action.reason;
      return result;
    default:
      return state;
  }
};
