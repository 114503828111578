import * as React from "react";
import copy from "copy-to-clipboard";
import { noa } from "../../voxel/noaInit";
const queryString = require("query-string");

export interface ILocationShareProps {}

export interface LocationShareState {
  copied: boolean;
}

export default class LocationShare extends React.PureComponent<
  ILocationShareProps,
  LocationShareState
> {
  constructor(props: ILocationShareProps) {
    super(props);

    this.state = {
      copied: false
    };
  }

  clickCopy = () => {
    var player = noa.playerEntity;
    let dat = noa.entities.getPositionData(player);

    let pos = {
      x: Math.floor(dat.position[0]),
      y: Math.floor(dat.position[1]),
      z: Math.floor(dat.position[2])
    };

    let url = [
      window.location.protocol,
      "//",
      window.location.host,
      window.location.pathname
    ].join("");
    let query = queryString.stringify(pos);
    copy(url + "?" + query);

    this.setState({
      copied: true
    });

    setTimeout(() => {
      this.setState({
        copied: false
      });
    }, 2000);
  };

  public render() {
    return (
      <div onClick={this.clickCopy} className="LocationShare">
        {this.state.copied ? "Copied" : "Link to this location"}
      </div>
    );
  }
}
