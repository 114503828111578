export type BLOCK_ID = keyof typeof BLOCK_DATA;

export const BLOCK_DATA = {
  light_red: { r: 255, g: 102, b: 102, noaIdx: 14 },
  red: { r: 184, g: 6, b: 44, noaIdx: 3 },
  orange: { r: 255, g: 124, b: 0, noaIdx: 7 },
  light_wood: { r: 204, g: 102, b: 0, noaIdx: 12 },
  medium_wood: { r: 99, g: 64, b: 16, noaIdx: 22 },
  wood: { r: 72, g: 2, b: 2, noaIdx: 5 },
  light_yellow: { r: 255, g: 255, b: 153, noaIdx: 15 },
  gold: { r: 238, g: 233, b: 13, noaIdx: 6 },
  dark_yellow: { r: 153, g: 153, b: 0, noaIdx: 20 },
  light_green: { r: 153, g: 255, b: 153, noaIdx: 21 },
  grass: { r: 26, g: 204, b: 52, noaIdx: 1 },
  dark_green: { r: 0, g: 102, b: 0, noaIdx: 13 },
  light_blue: { r: 153, g: 255, b: 255, noaIdx: 17 },
  dark_blue: { r: 0, g: 76, b: 153, noaIdx: 18 },
  blue: { r: 10, g: 115, b: 207, noaIdx: 2 },
  light_purple: { r: 255, g: 153, b: 255, noaIdx: 16 },
  purple: { r: 181, g: 10, b: 255, noaIdx: 8 },
  dark_purple: { r: 102, g: 0, b: 102, noaIdx: 19 },
  white: { r: 255, g: 255, b: 255, noaIdx: 9 },
  grey1: { r: 170, g: 170, b: 170, noaIdx: 11 },
  road: { r: 102, g: 102, b: 102, noaIdx: 4 },
  black: { r: 0, g: 0, b: 0, noaIdx: 10 }
};

export function getNoaBlockIdx(blockId: BLOCK_ID) {
  return BLOCK_DATA[blockId].noaIdx;
}

export let grassId = BLOCK_DATA.grass.noaIdx;
export let dirtId = BLOCK_DATA.road.noaIdx;
