import * as React from "react";
import { BLOCK_DATA, BLOCK_ID } from "../../common/constants/BLOCKS";
import { connect } from "react-redux";
import {
  setCurrentBlockId,
  setInventorySlot
} from "../../redux/actions/blockActions";
import { MyReduxState } from "../../redux/store";
import BlockItemDrop from "./BlockItemDrop";
import { SlotId } from "../../redux/reducers/inventoryReducer";
import { toggleInventory } from "../../redux/actions/gameUiActions";

const UNSELECTED_BLOCK_OUTLINE_COLOR = "black";
const SELECTED_BLOCK_OUTLINE_COLOR = "white";

export interface BlockSelectProps {
  setCurrentBlockId: typeof setCurrentBlockId;
  setInventorySlot: typeof setInventorySlot;
  inventory: MyReduxState["inventory"];
  isDropContainer?: boolean;
  toggleInventory: typeof toggleInventory;
}

export interface BlockSelectState {}

class BlockSelect extends React.PureComponent<
  BlockSelectProps,
  BlockSelectState
> {
  onSelectBlock(id: BLOCK_ID) {
    this.props.setCurrentBlockId(id);

    this.setState({
      selectedBlockId: id
    });
  }

  getBlockBorderColor(id: BLOCK_ID) {
    return this.props.inventory.activeItemId === id
      ? SELECTED_BLOCK_OUTLINE_COLOR
      : UNSELECTED_BLOCK_OUTLINE_COLOR;
  }

  changeInventorySlot(slotId: SlotId, blockId: any) {
    this.props.setInventorySlot(slotId, blockId.name);
  }

  renderColors() {
    return Object.keys(this.props.inventory.slots).map((key, idx) => {
      let slotId = key as SlotId;
      let item = this.props.inventory.slots[slotId];

      let blockId = item.blockId;
      let blockData = BLOCK_DATA[blockId];

      return (
        <div
          key={slotId}
          onClick={() => this.onSelectBlock(blockId)}
          className="blockColorItem"
          style={{
            backgroundColor: `rgb(${blockData.r}, ${blockData.g}, ${blockData.b})`,
            borderColor: this.getBlockBorderColor(blockId)
          }}
        >
          {this.props.isDropContainer && (
            <BlockItemDrop
              accept={["BlockItem"]}
              onDrop={item => this.changeInventorySlot(slotId, item)}
            />
          )}
          <div className="blockColorNumber">{idx + 1}</div>
        </div>
      );
    });
  }

  public render() {
    return (
      <div className="blockSelect">
        {!this.props.isDropContainer && (
          <div
            onClick={this.props.toggleInventory}
            className="blockSelect-Button"
          >
            🎨 E
          </div>
        )}
        {this.renderColors()}
      </div>
    );
  }
}

function mapStateToProps(state: MyReduxState) {
  const { inventory } = state;
  return { inventory };
}

export default connect(mapStateToProps, {
  setCurrentBlockId,
  setInventorySlot,
  toggleInventory: toggleInventory
})(BlockSelect);
