import nengi from "nengi";

class PlayerEntity {
  static protocol: any;
  rotX: number;
  rotY: number;
  rotZ: number;
  client: any;
  nid: number = 0;

  constructor(public x: number, public y: number, public z: number) {
    this.x = x;
    this.y = y;
    this.z = z;

    this.rotX = 0;
    this.rotY = 0;
    this.rotZ = 0;
  }
}

PlayerEntity.protocol = {
  x: { type: nengi.Float32, interp: true },
  y: { type: nengi.Float32, interp: true },
  z: { type: nengi.Float32, interp: true },
  rotX: { type: nengi.Float32 },
  rotY: { type: nengi.Float32 },
  rotZ: { type: nengi.Float32 }
};

export default PlayerEntity;
