import nengi from "nengi";
import nengiConfig from "../common/nengiConfig";

const client = new nengi.Client(nengiConfig, 100);

const state = {
  /* clientside state can go here */
};

// /* create hooks for any entity create, delete, and watch properties */
// clientHookAPI(client, createHooks(state));

client.on("connected", res => {
  console.log("Connected to multiplayer server");
});
client.on("disconnected", () => {
  console.log("connection closed");
});

// upgrade to being emitted events
client.onConnect(res => {
  client.emit("connected", res);
});

client.onClose(() => {
  client.emit("disconnected");
});

/* on('message::AnyMessage', msg => { }) */

export function getClient() {
  return client;
}

export function startClient() {
  // TODO use environment variables or something else
  let websocketHost = "wss://citycraft-ada47.appspot.com:443";

  // if (window.location.href.includes("localhost")) {
  //   websocketHost = "ws://localhost:8079"
  // }

  try {
    client.connect(websocketHost);
  } catch (e) {
    console.error("Cannot connect to websocket host", e);
  }
}
