import React from "react";
import { useDrop } from "react-dnd";

export interface DustbinProps {
  accept: string[];
  lastDroppedItem?: any;
  onDrop: (item: any) => void;
}

const BlockItemDrop: React.FC<DustbinProps> = ({
  accept,
  lastDroppedItem,
  onDrop
}) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const isActive = isOver && canDrop;
  let opacity = 0;
  let borderWidth = 1;

  if (isActive) {
    opacity = 0.5;
    borderWidth = 1;
  } else if (canDrop) {
    opacity = 0.1;
    borderWidth = 3;
  }

  return (
    <div
      ref={drop}
      className="Inventory-ItemDropZone"
      style={{ opacity, borderWidth, border: "1px solid black" }}
    >
      {/* {isActive
        ? 'Release to drop'
        : `This dustbin accepts: ${accept.join(', ')}`}

      {lastDroppedItem && (
        <p>Last dropped: {JSON.stringify(lastDroppedItem)}</p>
      )} */}
    </div>
  );
};

export default BlockItemDrop;
