import React from "react";
import { useDrag } from "react-dnd";

export interface BlockItemProps {
  name: string;
  type: string;
  isDropped: boolean;
  backgroundColor: string;
}

const BlockItem: React.FC<BlockItemProps> = ({
  name,
  type,
  isDropped,
  backgroundColor
}) => {
  const [{ opacity }, drag] = useDrag({
    item: { name, type },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });

  return (
    <div className="ColorGrid-Item" ref={drag} style={{ backgroundColor }}>
      {/* {isDropped ? <s>{name}</s> : name} */}
    </div>
  );
};

export default BlockItem;
