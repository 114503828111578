import nengi from "nengi";
import PlaceBlockCommand from "./commands/PlaceBlockCommand";
import MoveCommand from "./commands/MoveCommand";
import PlayerEntity from "./entity/PlayerEntity";
import Identity from "./messages/Identity";

const config = {
  UPDATE_RATE: 60,

  ID_BINARY_TYPE: nengi.UInt16,
  TYPE_BINARY_TYPE: nengi.UInt8,

  ID_PROPERTY_NAME: "nid",
  TYPE_PROPERTY_NAME: "ntype",

  protocols: {
    entities: [["PlayerEntity", PlayerEntity]],
    localMessages: [],
    messages: [
      ["BlockPlacedMessage", PlaceBlockCommand],
      ["IdentityCommand", Identity]
    ],
    commands: [
      ["PlaceBlockCommand", PlaceBlockCommand],
      ["MoveCommand", MoveCommand]
    ],
    basics: []
  }
};

export default config;
