/**
 *
 * Input processing component - gets (key) input state and
 * applies it to receiving entities by updating their movement
 * component state (heading, movespeed, jumping, etc.)
 *
 */
export default function (noa) {
  return {
    name: "receivesInputs",
    order: 20,
    state: {},
    onAdd: null,
    onRemove: null,
    system: function inputProcessor(dt, states) {
      var ents = noa.entities;
      var inputState = noa.inputs.state;
      var camHeading = noa.camera.heading;
      states.forEach(function (state) {
        var moveState = ents.getMovement(state.__id);
        var virtualInputState = ents.getVirtualInputs(state.__id);
        var buttonState = ents.getButtonState(state.__id);

        if (!buttonState || !buttonState.buttonStates) {
          buttonState = {};
          buttonState.buttonStates = {};
        }

        setMovementState(moveState, inputState, camHeading, virtualInputState, buttonState.buttonStates);
      });
    }
  };
}

function setMovementState(state, inputs, camHeading, virtualInputState, buttonState) {
  var virtualInputAngle;
  var joystickActive;

  if (!virtualInputState || !virtualInputState.dPadInputs) {
    virtualInputAngle = 0;
    joystickActive = false;
  } else {
    virtualInputAngle = virtualInputState.dPadInputs.angle / 180 * Math.PI;
    joystickActive = virtualInputState.dPadInputs.joystickActive;
  }

  state.jumping = !!inputs.jump;
  state.sprint = !!inputs.sprint;
  var fb = inputs.forward ? inputs.backward ? 0 : 1 : inputs.backward ? -1 : 0;
  var rl = inputs.right ? inputs.left ? 0 : 1 : inputs.left ? -1 : 0;
  state.didJustFly = false; // Are you flying or not?

  if (state.lastFlyInput != inputs.fly) {
    if (inputs.fly) {
      state.isFlying = !state.isFlying;

      if (state.isFlying) {
        state.didJustFly = true;
      }
    }
  }

  if (buttonState.flyPressed) {
    state.isFlying = true;
    state.didJustFly = true;
  }

  state.lastFlyInput = inputs.fly;
  state.jumpPressed = inputs.jump || buttonState.upPressed;
  state.crouchPressed = inputs.crouch || buttonState.downPressed;

  if (joystickActive) {
    state.running = true;
    camHeading += -virtualInputAngle + Math.PI / 2;
    state.heading = camHeading;
    return;
  } else {
    state.running = false;
  }

  if ((fb | rl) === 0) {
    state.running = false;
  } else {
    state.running = true;

    if (fb) {
      if (fb == -1) camHeading += Math.PI;

      if (rl) {
        camHeading += Math.PI / 4 * fb * rl; // didn't plan this but it works!
      }
    } else {
      camHeading += rl * Math.PI / 2;
    }

    state.heading = camHeading;
  }
}