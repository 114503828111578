import React from "react";
import "./GetStarted.css";

export interface GetStartedProps {
  onClickStart(): void;
}

export default class GetStarted extends React.PureComponent<GetStartedProps> {
  renderStartButton = () => {
    return <button className="App-ClickStart">Click To Start</button>;
  };

  public render() {
    return (
      <div
        onClick={this.props.onClickStart}
        className="App-ClickStartContainer"
      >
        <div className="GetStarted-Title">Welcome to CityCraft.io!</div>
        <div>
          <div>
            Minecraft style MMO in your browser! Play with your friends by
            sending them this link!
          </div>
          <div>Left click to place, right click to remove</div>
          <div>Press F to fly. Enjoy building and be nice!</div>
        </div>
        {this.renderStartButton()}
      </div>
    );
  }
}
