import nengi from "nengi";
import NengiCommand from "./NengiCommand";

class MoveCommand extends NengiCommand {
  constructor(public x: number, public y: number, public z: number) {
    super();
    this.x = x;
    this.y = y;
    this.z = z;
  }
}

MoveCommand.protocol = {
  x: nengi.Float32,
  y: nengi.Float32,
  z: nengi.Float32
};

export default MoveCommand;
