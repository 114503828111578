import axios from "axios";
import {
  inBuildZone,
  isOutOfBounds,
  UPPER_BOUNDARY,
  CHUNK_SIZE
} from "./voxelUtils";
import { dirtId, grassId } from "../common/constants/BLOCKS";
import { localBlockMap } from "./noaInputHandlers";
var rle = require("voxel-crunch");

interface ChunkRequest {
  chunkId: string;
  resolver(response: any): void;
  rejecter(error: any): void;
}

interface ChunkResolver {
  resolver(response: any): void;
  rejecter(error: any): void;
}

interface ChunkResponse {
  chunkId: string;
  compressed: string;
  length: number;
}

// x,y,z are coordinates of lower block of chunk
export async function genChunk(
  id: string,
  data: any,
  x: number,
  y: number,
  z: number
) {
  // NETWORK CALLS!
  // if 0 or shape-1, then it's actually in the neighboring chunk. So return 0 for now, and then
  // Iterate through every other block, get the value from string (if it exists, otherwise 0)
  // And the ground tiles should be pre-built
  //let chunkData = await getChunkDataFromServer(id);

  let chunkCoords = id.split("|");
  let chunkX = parseInt(chunkCoords[0]);
  let chunkY = parseInt(chunkCoords[1]);
  let chunkZ = parseInt(chunkCoords[2]);

  // Needed because new version of noa added worldName to ID
  let basicId = `${chunkX}|${chunkY}|${chunkZ}`;

  // Edge of world
  if (isOutOfBounds(chunkX, chunkY, chunkZ)) {
    return;
  }

  let isDefault = chunkY === -1;
  let chunkData;

  if (chunkY > UPPER_BOUNDARY || chunkY < -1) {
    return;
  } else if (!isDefault) {
    chunkData = await queueChunkId(basicId);

    // Change the stride because the server stores
    // 3D arrays with a different one than Noa
    data.stride[0] = 1;
    data.stride[1] = 2500;
    data.stride[2] = 50;

    // Set the data from the server
    data.data.set(chunkData);

    // Now, set individuals blocks from local cache
    if (localBlockMap.has(basicId)) {
      let blockSet = localBlockMap.get(basicId);

      if (blockSet) {
        blockSet.forEach(blockUpdate => {
          let innerX = blockUpdate.x - x;
          let innerY = blockUpdate.y - y;
          let innerZ = blockUpdate.z - z;

          data.set(innerX, innerY, innerZ, blockUpdate.type);
        });
      }
    }
    return;
  }

  for (var i = 0; i < data.shape[0]; i++) {
    for (var j = 0; j < data.shape[1]; j++) {
      for (var k = 0; k < data.shape[2]; k++) {
        var voxelID = 0;
        let absX = x + i;
        let absZ = z + k;

        // Default voxels
        if (y + j < 0) {
          voxelID = getVoxelID(absX, y + j, absZ);
        }

        // Adds red border to edge
        if (y + j < 1) {
          if (absX === 749 || absX === -700 || absZ === 749 || absZ === -700) {
            voxelID = 3;
          }
        }

        data.set(i, j, k, voxelID);
      }
    }
  }
}

let numRequests = 0;
let dataTransferredBytes = 0;

async function getChunkDataFromServer(chunkId: string) {
  numRequests++;
  // console.log("Total requests", numRequests);

  let request: any = {
    url: `https://us-central1-citycraft-ada47.cloudfunctions.net/api/chunks/${chunkId}`,
    headers: {
      "content-type": "application/json"
    },
    method: "GET"
  };

  let result = new Uint8Array(0);

  try {
    let response = await axios(request);

    if (response.data && response.data.length) {
      let data = response.data;

      if (data.length === 0) {
        return result;
      }

      // Convert string to buffer
      const deserializedBuffer = data.compressed.split(",");
      // @ts-ignore
      const newBuffer = Uint8Array.from(deserializedBuffer);

      // let length = parseInt(response.headers['Chunk-Length']);

      // // Uncompress chunked buffer
      result = new Uint8Array(data.length);
      rle.decode(newBuffer, result);

      dataTransferredBytes += newBuffer.length;
      // console.log(
      //   "Total data transferred " + dataTransferredBytes / 1000 + "kB"
      // );
    }
  } catch (e) {
    console.error("Error in getChunk", e);
  }

  return result;
}

// TODO use a better chunkSize global (and shared via server)
function getBlockTypeFromData(data: any, x: number, y: number, z: number) {
  let idx = y * CHUNK_SIZE * CHUNK_SIZE + z * CHUNK_SIZE + x;

  if (idx >= data.length) {
    return 0;
  } else {
    return data[idx];
  }
}

async function queueChunkId(chunkId: string): Promise<Uint8Array> {
  let result = await getChunkDataFromServer(chunkId);
  return result;
}

function getVoxelID(x: number, y: number, z: number) {
  if (inBuildZone(x, y, z)) {
    return grassId;
  } else {
    return dirtId;
  }

  // if (y < -3) return dirtID
  // var height = 5 * Math.sin(x / 10) + 3 * Math.cos(z / 20)
  // if (y < height) return grassID
  // return 0 // signifying empty space
  // let idx = Math.floor(Math.random() * 3);
  // return choices[idx];

  // if (y < 3) {
  //     return grassID
  // } else {
  //     return 0
  // }
}
