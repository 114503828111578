import nengi from "nengi";

class Identity {
  static protocol: any;
  entityId: number;

  constructor(id: number) {
    this.entityId = id;
  }
}

Identity.protocol = {
  entityId: nengi.UInt16
};

export default Identity;
