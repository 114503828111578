import * as React from "react";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { connect } from "react-redux";
import { setCurrentBlockId } from "../../redux/actions/blockActions";
import { BLOCK_ID } from "../../common/constants/BLOCKS";
import { MyReduxState } from "../../redux/store";
import { SlotId } from "../../redux/reducers/inventoryReducer";
import { toggleInventory } from "../../redux/actions/gameUiActions";

export interface InputHandlerProps {
  setCurrentBlockId: typeof setCurrentBlockId;
  toggleInventory: typeof toggleInventory;
  inventory: MyReduxState["inventory"];
}

class InputHandler extends React.PureComponent<InputHandlerProps> {
  handleInventoryKeys(key: string) {
    let inventory: BLOCK_ID[] = [];
    for (var slotId in this.props.inventory.slots) {
      let itemData = this.props.inventory.slots[slotId as SlotId];
      inventory.push(itemData.blockId);
    }

    let idx = parseInt(key);

    if (!isNaN(idx) && idx < 9) {
      let color = inventory[idx - 1] as BLOCK_ID;
      this.props.setCurrentBlockId(color);
    }
  }

  public render() {
    return (
      <KeyboardEventHandler
        handleKeys={["1", "2", "3", "4", "5", "6", "7", "8", "e"]}
        handleFocusableElements={true}
        onKeyEvent={(key: any, e: any) => {
          if (key === "e") {
            this.props.toggleInventory();
          } else {
            this.handleInventoryKeys(key);
          }
        }}
      />
    );
  }
}

function mapStateToProps(state: MyReduxState) {
  const { inventory } = state;
  return { inventory };
}

export default connect(mapStateToProps, {
  setCurrentBlockId: setCurrentBlockId,
  toggleInventory: toggleInventory
})(InputHandler);
