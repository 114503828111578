import Engine from "noa-engine";
import { registerBlocks } from "./blockRegistry";
import { networkUpdate } from "../network/networkUpdate";
import { playerMesh, initScene } from "./sceneInit";
import { genChunk } from "./worldGen";
import { startInputHandlers } from "./noaInputHandlers";
import { startClient } from "../network/nengiClient";
import { updateOnTick } from "./readBlocksFirestore";
import { CHUNK_SIZE } from "./voxelUtils";

const queryString = require("query-string");
const parsed = queryString.parse(window.location.search);
let inverseY = false;

if (parsed && parsed.invertY) {
  inverseY = true;
}

let opts = {
  debug: false,
  showFPS: false,
  chunkSize: CHUNK_SIZE,
  chunkAddDistance: 2.5,
  chunkRemoveDistance: 5.5,
  playerAutoStep: true,
  inverseY
  // See `test` example, or noa docs/source, for more options
};
export let noa = new Engine(opts);
noa.camera.sensitivityX = 20;
noa.camera.sensitivityY = 20;

// Start client
startClient();

// Register blocks
registerBlocks();

// start input handlers
startInputHandlers();

// Init scene
initScene();

function getRandom(num: number) {
  return Math.floor(Math.random() * num);
}

// setInterval(() => {
//   noa.setBlock(3, [100,100,100].map(getRandom));
// }, 100);

// Main Noa events (tick, world needed)
noa.on("tick", function(dt: number) {
  // console.log(dat);
  // var scroll = noa.inputs.state.scrolly;
  // if (scroll !== 0) {
  //   noa.camera.zoomDistance += scroll > 0 ? 1 : -1;
  //   if (noa.camera.zoomDistance <= 0) {
  //     if (playerMesh) {
  //       playerMesh.visibility = 0;
  //     }

  //     noa.camera.zoomDistance = 0;
  //   } else {
  //     if (playerMesh) {
  //       playerMesh.visibility = 1;
  //     }
  //   }

  //   if (noa.camera.zoomDistance > 10) {
  //     noa.camera.zoomDistance = 10;
  //   }
  // }

  // Check which block you are in
  // And update listeners
  var player = noa.playerEntity;
  let dat = noa.entities.getPositionData(player);

  updateOnTick(dat.position[0], dat.position[1], dat.position[2]);

  networkUpdate();
});

// register for world events
noa.world.on("worldDataNeeded", async function(
  id: string,
  data: any,
  x: number,
  y: number,
  z: number,
  worldName: string
) {
  // `id` - a unique string id for the chunk
  // `data` - an `ndarray` of voxel ID data (see: https://github.com/scijs/ndarray)
  // `x, y, z` - world coords of the corner of the chunk
  // console.log("World shape", data.shape[0], data.shape[1], data.shape[2]);
  // console.log("sop");
  await genChunk(id, data, x, y, z);
  // console.log("sup");

  // tell noa the chunk's terrain data is now set
  noa.world.setChunkData(id, data);
});
