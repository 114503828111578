import * as React from "react";

export interface IHelpBannerProps {
  isTouchDevice: boolean;
}

export default class HelpBanner extends React.PureComponent<IHelpBannerProps> {
  renderTouchControls() {
    return (
      <div>
        <div>
          <b>Joystick</b> - Move
        </div>
        <div>
          <b>Drag</b> - Look
        </div>
        <div>
          <b>Tap</b> - Place block
        </div>
      </div>
    );
  }

  renderDesktopControls() {
    return (
      <div>
        <div>
          <b>WASD/Arrows</b> - Move
        </div>
        <div>
          <b>Left Click</b> - Place
        </div>
        <div>
          <b>Right Click</b> - Remove
        </div>
        <div>
          <b>E</b> - Inventory
        </div>
        <div>
          <b>F</b> - Toggle Fly
        </div>
        <div>
          <b>Space</b> - Jump/Ascend
        </div>
        <div>
          <b>C</b> - Descend
        </div>
      </div>
    );
  }

  goToDiscord = () => {
    window.location.href = "https://discord.gg/86wYcHN";
  };

  public render() {
    let controls = this.props.isTouchDevice
      ? this.renderTouchControls()
      : this.renderDesktopControls();

    return (
      <div className="App-HelpBanner">
        <div className="HelpBanner-Title">CityCraft.io</div>
        <div className="HelpBanner-Controls">
          <div>
            <u>Controls</u>
          </div>
          {controls}
        </div>
        <div onClick={this.goToDiscord} className="HelpBanner-Discord">
          DISCORD
        </div>
      </div>
    );
  }
}
