import { BLOCK_ID } from "../../common/constants/BLOCKS";
import { SlotId } from "../reducers/inventoryReducer";

export const setCurrentBlockId = (id: BLOCK_ID) => ({
  type: "SET_ACTIVE_ITEM",
  itemId: id
});

export const setInventorySlot = (slotId: SlotId, itemId: BLOCK_ID) => ({
  type: "SET_INVENTORY_SLOT",
  slotId,
  itemId
});
