import { BLOCK_DATA, BLOCK_ID } from "../common/constants/BLOCKS";
import { noa } from "./noaInit";

export function registerBlocks() {
  Object.keys(BLOCK_DATA).forEach((blockId, idx) => {
    let blockData = BLOCK_DATA[blockId as BLOCK_ID];

    let noaBlockId = blockData.noaIdx;

    let color = [blockData.r / 255, blockData.g / 255, blockData.b / 255];
    noa.registry.registerMaterial(blockId, color, null);
    noa.registry.registerBlock(noaBlockId, { material: blockId });
  });
}
