import * as React from "react";
import { BLOCK_DATA, BLOCK_ID } from "../../common/constants/BLOCKS";
import { connect } from "react-redux";
import { setCurrentBlockId } from "../../redux/actions/blockActions";
import { MyReduxState } from "../../redux/store";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import BlockSelect from "./BlockSelect";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import TouchBackend from "react-dnd-touch-backend";
import "../../css/Inventory.css";
import BlockItem from "./BlockItem";
import { toggleInventory } from "../../redux/actions/gameUiActions";
import { noa } from "../../voxel/noaInit";

var isTouchDevice = require("is-touch-device");

export interface InventoryProps {
  setCurrentBlockId: typeof setCurrentBlockId;
  toggleInventory: typeof toggleInventory;
  isInterfaceOpen: boolean;
}

export interface InventoryState {}

class Inventory extends React.PureComponent<InventoryProps, InventoryState> {
  componentDidUpdate(prevProps: InventoryProps) {
    if (prevProps.isInterfaceOpen && !this.props.isInterfaceOpen) {
      noa.container.setPointerLock(true);
    }

    if (!prevProps.isInterfaceOpen && this.props.isInterfaceOpen) {
      noa.container.setPointerLock(false);
    }
  }

  toggle = () => {
    this.props.toggleInventory();
  };

  renderColorGrid() {
    let result = [];

    for (const key in BLOCK_DATA) {
      let blockData = BLOCK_DATA[key as BLOCK_ID];

      let color = `rgb(${blockData.r}, ${blockData.g}, ${blockData.b})`;
      let id = key;
      result.push(
        <BlockItem
          key={id}
          name={id}
          backgroundColor={color}
          type="BlockItem"
          isDropped={false}
        />
      );
    }

    return <div className="ColorGrid">{result}</div>;
  }

  public render() {
    return (
      <Modal isOpen={this.props.isInterfaceOpen} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>Inventory</ModalHeader>
        <ModalBody>
          <DndProvider backend={isTouchDevice() ? TouchBackend : Backend}>
            Drag any of the colors to the hotbar below.
            {this.renderColorGrid()}
            <div className="BlockSelect-Container">
              <BlockSelect isDropContainer={true} />
            </div>
          </DndProvider>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state: MyReduxState) {
  let isInterfaceOpen = state.gameUi.isInterfaceOpen;
  return {
    isInterfaceOpen
  };
}

export default connect(mapStateToProps, {
  setCurrentBlockId,
  toggleInventory: toggleInventory
})(Inventory);
