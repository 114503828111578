import * as React from "react";
import { connect } from "react-redux";
import { MyReduxState } from "../../redux/store";

export interface IErrorTextProps {
  gameUi: MyReduxState["gameUi"];
}

interface ErrorTextState {
  showError: boolean;
}

class ErrorText extends React.PureComponent<IErrorTextProps, ErrorTextState> {
  constructor(props: IErrorTextProps) {
    super(props);

    this.state = {
      showError: false
    };
  }

  componentDidUpdate(prevProps: IErrorTextProps) {
    if (prevProps.gameUi.isError !== this.props.gameUi.isError) {
      this.setState({
        showError: true
      });

      setTimeout(this.hideError, 1000);
    }
  }

  hideError = () => {
    this.setState({
      showError: false
    });
  };

  public render() {
    if (!this.state.showError) {
      return null;
    }

    return (
      <div className="ErrorText ErrorText-Fade">
        {this.props.gameUi.errorReason}
      </div>
    );
  }
}

function mapStateToProps(state: MyReduxState) {
  const { gameUi } = state;
  return { gameUi };
}

export default connect(mapStateToProps)(ErrorText);
