import * as React from "react";
import { noa } from "../../voxel/noaInit";
import { eraseBlock } from "../../voxel/noaInputHandlers";
var isTouchDevice = require("is-touch-device");

export interface IFlyControlsProps {}

interface ButtonStates {
  downPressed: boolean;
  upPressed: boolean;
  flyPressed: boolean;
}

export default class FlyControls extends React.PureComponent<
  IFlyControlsProps
> {
  buttonStates: ButtonStates = {
    downPressed: false,
    upPressed: false,
    flyPressed: isTouchDevice()
  };

  componentDidMount() {
    var player = noa.playerEntity;

    noa.entities.addComponent(player, noa.entities.names.buttonInputs, {
      buttonStates: this.buttonStates
    });
  }

  pressButton(name: keyof ButtonStates) {
    this.buttonStates[name] = true;
  }

  releaseButton(name: keyof ButtonStates) {
    this.buttonStates[name] = false;
  }

  public render() {
    return (
      <div className="FlyControls">
        <div
          onTouchStart={() => this.pressButton("upPressed")}
          onTouchEnd={() => this.releaseButton("upPressed")}
          onTouchCancel={() => this.releaseButton("upPressed")}
          className="FlyControls-Button"
        >
          Up
        </div>
        <div
          onTouchStart={() => this.pressButton("downPressed")}
          onTouchEnd={() => this.releaseButton("downPressed")}
          onTouchCancel={() => this.releaseButton("downPressed")}
          className="FlyControls-Button"
        >
          Down
        </div>
        <div
          style={{ marginTop: 8 }}
          onClick={eraseBlock}
          className="FlyControls-Button"
        >
          ERASE
        </div>
      </div>
    );
  }
}
