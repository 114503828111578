import nengi from "nengi";
import NengiCommand from "./NengiCommand";

export default class PlaceBlockCommand extends NengiCommand {
  constructor(
    public x: number,
    public y: number,
    public z: number,
    public type: number
  ) {
    super();

    this.x = x;
    this.y = y;
    this.z = z;
    this.type = type;
  }
}

PlaceBlockCommand.protocol = {
  x: nengi.Int32,
  y: nengi.Int32,
  z: nengi.Int32,
  type: nengi.UInt8
};
